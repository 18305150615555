import { ArrowBack, Close } from '@mui/icons-material';
import type { DrawerProps, ModalProps as MuiModalProps } from '@mui/material';
import { Box, Drawer, Fade, Modal as MuiModal, Portal, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import type React from 'react';
import { theme } from 'src/theme';

import type { ProgressProps } from './atoms/Progress';
import { Progress } from './atoms/Progress';

type ModalContainerProps = DrawerProps & MuiModalProps;
const ModalContainer = ({ children, open, onClose, sx, ...rest }: ModalContainerProps) => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));

  if (isMobile)
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        sx={{ ...sx }}
        {...rest}
        PaperProps={{ sx: { borderTopLeftRadius: '10px', borderTopRightRadius: '10px' } }}
      >
        {children}
      </Drawer>
    );

  return (
    <MuiModal open={open} onClose={onClose} {...rest} closeAfterTransition sx={{ ...sx }}>
      <Fade in={open}>
        <Box
          display="flex"
          sx={{ overflowY: 'hidden', background: theme.colors.white, maxHeight: 'calc(100vh - 32px)' }}
          borderRadius="10px"
        >
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};
ModalContainer.displayName = 'ModalContainer';

export type ModalProps = ModalContainerProps &
  Partial<ProgressProps> & {
    multistep?: boolean;
    onBack?: () => void;
  };

/**
 *@param: {boolean} steps - A boolean to tell if the modal is a multistep modal. (requires steps and currentStep to be passed as well)
 *@param: {number} steps - The number of steps in the multistep modal.
 *@param: {number} currentStep - The current step of the multistep modal (1-indexed).
 */
export const Modal = ({ open, onClose, onBack, title, multistep, steps, currentStep, children, ...rest }: ModalProps) => {
  const isMultistep = multistep && steps && currentStep;

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) onClose(event, 'backdropClick');
  };

  return (
    <Portal>
      <ModalContainer open={open} onClose={onClose} disableAutoFocus {...rest}>
        <Stack width="100%">
          {/* Header */}
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding={isMultistep ? '32px 24px' : '24px'}
            borderBottom={isMultistep ? 'none' : `1px solid ${theme.colors.whisperGrey}`}
            position="sticky"
            top="0px"
            sx={{ background: theme.colors.white, zIndex: 5 }}
          >
            {isMultistep &&
              (onBack && currentStep != 1 ? (
                <IconButton
                  aria-label="back"
                  onClick={() => {
                    onBack();
                  }}
                  sx={{ color: theme.colors.mediumBlack, '&:hover': { boxShadow: 'none' }, p: 0 }}
                  size="medium"
                >
                  <ArrowBack />
                </IconButton>
              ) : (
                <Box></Box>
              ))}

            <Typography variant="subtitle2">{title}</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ color: theme.colors.mediumBlack, '&:hover': { boxShadow: 'none' }, p: 0 }}
              size="medium"
            >
              <Close />
            </IconButton>
          </Stack>

          {isMultistep && (
            <Box padding="0px 24px">
              <Progress steps={steps} currentStep={currentStep} />
            </Box>
          )}

          {/* Body */}
          <Box padding="32px 24px" sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            <Box>{children}</Box>
          </Box>
        </Stack>
      </ModalContainer>
    </Portal>
  );
};

Modal.displayName = 'Modal';
