import { createContext, useEffect } from 'react';

import type { AutomobileCatalog } from 'src/hooks/useLookups';
import useLookups from 'src/hooks/useLookups';

export const VehicleLookupsContext = createContext<AutomobileCatalog | null>(null);

type VehicleLookupsProviderProps = {
  children: React.ReactNode;
};

export const VehicleLookupsProvider = ({ children }: VehicleLookupsProviderProps) => {
  const { lookupState, getAllLookups } = useLookups();

  useEffect(() => {
    getAllLookups();
  }, []);

  return <VehicleLookupsContext.Provider value={lookupState}>{children}</VehicleLookupsContext.Provider>;
};
